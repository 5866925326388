import React from 'react';

export default ({ children }) => {
  const components = children.reduce(
    (acc, post, i) => {
      if (i % 2 === 0) {
        acc.col1.push(post);
      } else {
        acc.col2.push(post);
      }

      return acc;
    },
    { col1: [], col2: [] }
  );

  return (
    <div className="columns">
      <div className="column">{components.col1}</div>
      <div className="column">{components.col2}</div>
    </div>
  );
};
