import React from 'react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import TwoColCardSection from '../components/twoColCardSection';
import PostCard from '../components/postCard';

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostCard key={edge.node.id} post={edge.node} />);

  return (
    <Layout>
      <Helmet>
        <title>Chris The Fermenter</title>
      </Helmet>
      <ImageContainer>
        <Img fixed={data.bread.childImageSharp.fixed} />
        <Img fixed={data.yeast.childImageSharp.fixed} />
        <Img fixed={data.pizza.childImageSharp.fixed} />
      </ImageContainer>
      <h1>Recent Posts</h1>
      <TwoColCardSection>{posts}</TwoColCardSection>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    bread: file(relativePath: { eq: "home/bread.png" }) {
      childImageSharp {
        fixed(width: 310, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pizza: file(relativePath: { eq: "home/pizza.jpg" }) {
      childImageSharp {
        fixed(width: 310, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    yeast: file(relativePath: { eq: "home/yeast.png" }) {
      childImageSharp {
        fixed(width: 310, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
