import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

const Figure = styled.figure`
  margin: 0;
`;

const TitleText = styled.span`
  font-size: 1.5rem;
`;

const Card = styled.div`
  margin-bottom: 2rem;
  color: #717171;
`;

export default ({ path, fluidImg, title, date }) => (
  <Card className="card">
    <div className="card-image">
      <Link to={path}>
        <Figure className="image is-4by3">
          {fluidImg && <Img fluid={fluidImg} />}
        </Figure>
      </Link>
    </div>
    <div className="card-content">
      <div className="media">
        <div className="media-content">
          <div className="title">
            <Link to={path}>
              <TitleText>{title}</TitleText>
            </Link>
          </div>
          <div className="subtitle">
            <time>{date}</time>
          </div>
        </div>
      </div>
    </div>
  </Card>
);
