import React from 'react';

import LinkCard from './linkCard';

export default ({ post }) => (
  <LinkCard
    path={post.frontmatter.path}
    title={post.frontmatter.title}
    date={post.frontmatter.date}
    fluidImg={post.frontmatter.featuredImage.childImageSharp.fluid}
  />
);
